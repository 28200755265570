import Choices from "choices.js";
import "./hoa-custom-select.css";

class HoaCustomSelect extends HTMLElement {
	public constructor() {
		super();

		const selectElement =
			this.querySelector<HTMLSelectElement>(":scope > select");

		if (!selectElement) {
			console.error("hoa-custom-select: No select element found");
			return;
		}

		const isStaticPosition = this.dataset.staticPosition === "true";

		new Choices(selectElement, {
			searchEnabled: false,
			itemSelectText: "",
			shouldSort: false,
			position: isStaticPosition ? "bottom" : "auto",
			classNames: {
				...Choices.defaults.options.classNames,
				containerOuter:
					"choices hoa-custom-select" +
					(isStaticPosition ? " choices--static" : ""),
			},
		});
	}
}

customElements.define("hoa-custom-select", HoaCustomSelect);
